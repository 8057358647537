import { createStore } from 'vuex'
import { useCookies } from "vue3-cookies";
import auth from '@/auth';
import axios from 'axios'

const copyKeys = ["user_id","team","influencer","client","client_id","account_id","full_name","nickname","navs"];
const cookies = useCookies().cookies;

if (!cookies.isKey('theme')) cookies.set('theme','default',60*60*24*365); // 1 year
if (!cookies.isKey('currentClient')) cookies.set('currentClient',0,60*60*24*14); // 14 days
if (!cookies.isKey('usersFilter')) cookies.set('usersFilter',"[]",60*60*24*14); // 14 days

export default createStore({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: cookies.get('theme'),
    currentClient: cookies.get('currentClient'),
    usersFilter: JSON.parse(cookies.get('usersFilter')),
    user: null
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
      cookies.set('theme',payload.value,60*60*24*365)
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateCurrentClient(state,clientId) {
      state.currentClient = clientId;
      cookies.set('currentClient',clientId,60*60*24*14)
    },
    updateUsersFilter(state,filterUsers) {
      state.usersFilter = filterUsers;
      cookies.set('usersFilter',JSON.stringify(filterUsers),60*60*24*14)
    },
    updateUser(state, user) {
      state.user = user;
    },
    logoutUser(state) {
      state.user = null;
    }
  },
  getters: {
    user: state => state.user,
    isLogged: state => (state.user !== null),
    appVersion: state => state.packageVersion
  },
  actions: {
    setCurrentUser: async ({ commit }) => {
      let currentUser = auth.user();
      await axios.post(process.env.VUE_APP_API_URL + 'auth/user',currentUser)
        .then((response) => {
          let dbUser = response.data;
          for (let i = 0; i < copyKeys.length; i++) {
            const key = copyKeys[i];
            currentUser[key] = dbUser[key];
          }
          currentUser.privileges = JSON.parse("[" + dbUser.privileges + "]");
          commit('updateUser',currentUser);
        })
        .catch((error) => {
          console.log(error);
          auth.logout().then(() => {
            commit('logoutUser');
          })
        })
      
    }
  },
  modules: {},
})
