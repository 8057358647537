import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Nl2br from 'vue3-nl2br'
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import Vue3MobileDetection from "vue3-mobile-detection";
import VueClipboard from 'vue-clipboard2'
// import VuePdf from 'vue3-pdfjs'

import auth from '@/auth';
auth.init().then(() => {
  const app = createApp(App)
  app.use(store)
  app.use(router)
  app.use(CoreuiVue)
  app.use(VueAxios, axios)
  app.use(VCalendar, {})
  app.use(Vue3MobileDetection)
  app.use(VueClipboard)
  // app.use(VuePdf)
  app.provide('icons', icons)
  app.component('CIcon', CIcon)
  app.component('nl2br', Nl2br)

  app.mount('#app')
});


