<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <div class="avatar avatar-md">
        <img class="avatar-img" :src="$store.getters.isLogged && $store.state.user.photoURL ? $store.state.user.photoURL : avatar" referrerpolicy="no-referrer">
      </div>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="fw-semibold py-2">
        {{ $store.getters.isLogged ? $store.state.user.full_name : 'ni prijavljen' }}
      </CDropdownHeader>
      <CDropdownItem @click="userSignOut"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatar.jpeg'
import auth from '@/auth';

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    userSignOut() {
      auth.logout().then(() => {
        this.$store.commit('logoutUser');
        this.$router.push('/login');
      })
    }
  }
}
</script>
