// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import store from '@/store';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1oTF3XRpmfvIgG3wzeSySgWcAPQ6Adss",
  authDomain: "ddstudios.firebaseapp.com",
  projectId: "ddstudios",
  storageBucket: "ddstudios.appspot.com",
  messagingSenderId: "667232985555",
  appId: "1:667232985555:web:88acd47acb47a8fe474f26",
  measurementId: "G-169ZQ4585H"
};

const auth = {
  userPromise: null,
  gAuth: null,

  init() {
    initializeApp(firebaseConfig);
    this.gAuth = getAuth();
    // this.gAuth.onAuthStateChanged(() => store.dispatch('setCurrentUser'));

    this.userPromise = new Promise(resolve => {
      if (store.state.user) {
        resolve(true);
      } else {
        const unsubscribe = this.gAuth.onAuthStateChanged(function (user) {
          unsubscribe();
          if (user) {
            store.dispatch('setCurrentUser').then(() => {
              resolve(true);
            });
          } else {
            resolve(false);
          }
        });
      }
    });

    return this.userPromise
  },
  user() {
    return this.gAuth.currentUser;
  },
  logout() {
    return signOut(this.gAuth);
  }
}

export default auth;