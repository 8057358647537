<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <img :src="logo" height="44">
    </CSidebarBrand>
    <AppSidebarNavs />
    <CButtonGroup aria-label="Theme switch" v-if="$isMobile()">
      <CFormCheck
        id="btn-light-theme"
        type="radio"
        :button="{ color: 'primary' }"
        name="theme-switch"
        autocomplete="off"
        :checked="$store.state.theme === 'default'"
        @change="
          (event) =>
            $store.commit({
              type: 'toggleTheme',
              value: 'default',
            })
        "
      >
        <template #label><CIcon icon="cil-sun" /></template>
      </CFormCheck>
      <CFormCheck
        id="btn-dark-theme"
        type="radio"
        :button="{ color: 'primary' }"
        name="theme-switch"
        autocomplete="off"
        :checked="$store.state.theme === 'dark'"
        @change="
          (event) =>
            $store.commit({
              type: 'toggleTheme',
              value: 'dark',
            })
        "
      >
        <template #label><CIcon icon="cil-moon" /></template>
      </CFormCheck>
    </CButtonGroup>
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppSidebarNavs from './AppSidebarNavs'
import logo from '@/assets/brand/ddstudios-logo.png'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNavs,
  },
  setup() {
    const store = useStore()
    return {
      logo,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
<style>
.sidebar {
  background-color: rgb(28, 28, 28);
}
.sidebar-brand {
  background-color: rgb(21, 21, 21);
}
.nav-link, .nav-icon {
  color: rgba(255, 255, 255, 0.9) !important;
}
</style>
